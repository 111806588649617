import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import queryString from "query-string"
import { Section } from "../components/templates/Section"
import Cloud from "../styles/styledComponents/cloud"
import TextStroke from "../styles/styledComponents/textStroke"
import RichText from "../components/atoms/rich-text"
import Layout from "../components/templates/layout"
import ButtonExternalLink from "../components/atoms/Button/buttonExternalLink"
import useStoryblok from "../lib/storyblok"
import VectorGraphic from "../components/atoms/vectorGraphic"
import OfferCard from "../components/molecules/offerCard"
import axios from "axios"
import Grid from "../components/atoms/grid"

const Eligible = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location)
  const { content } = story

  const [priceData, setPriceData] = useState({})
  const [customerID, setCustomerID] = useState("")
  const [priceID, setPriceID] = useState("")
  const [durationOfTenancy, setDurationOfTenancy] = useState(0)

  useEffect(() => {
    setCustomerID(queryString.parse(location.search).customerID)
    setPriceID(queryString.parse(location.search).priceID)
    setDurationOfTenancy(queryString.parse(location.search).duration)
  }, [location])

  useEffect(() => {
    axios
      .get(`/.netlify/functions/getPrice/?priceID=${priceID}`, {})
      .then(function (response) {
        setPriceData(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [priceID])

  const cost = parseFloat(priceData.amount / 100).toFixed(2)
  const paymentUrl = `/.netlify/functions/checkout?priceID=${priceID}&customerID=${customerID}&duration=${durationOfTenancy}`

  return (
    <Layout location={location} footerData={content.footer} hideNavigation>
      <div className="bg-blue-lightest relative">
        <Cloud
          width="217"
          height="75"
          fill="none"
          viewBox=""
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          number={1}
        >
          <path
            opacity=".1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.15 69.558V75H0v-1.133c0-8.246 3.35-15.72 8.77-21.122a29.874 29.874 0 0121.171-8.751c8.825 0 16.754 3.815 22.234 9.873v-8.002c0-12.666 5.147-24.132 13.46-32.433C73.953 5.13 85.443 0 98.135 0c20.949 0 38.615 13.993 44.152 33.116a45.815 45.815 0 0127.898-9.428c25.383 0 45.965 20.54 45.965 45.87z"
            fill="#092FF4"
          />
        </Cloud>
        <Cloud
          width="217"
          height="75"
          fill="none"
          viewBox=""
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          number={2}
        >
          <path
            opacity=".1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.15 69.558V75H0v-1.133c0-8.246 3.35-15.72 8.77-21.122a29.874 29.874 0 0121.171-8.751c8.825 0 16.754 3.815 22.234 9.873v-8.002c0-12.666 5.147-24.132 13.46-32.433C73.953 5.13 85.443 0 98.135 0c20.949 0 38.615 13.993 44.152 33.116a45.815 45.815 0 0127.898-9.428c25.383 0 45.965 20.54 45.965 45.87z"
            fill="#092FF4"
          />
        </Cloud>
        <Grid HTMLelement="figure" className="z-10 relative top-20">
          <VectorGraphic
            name="eligible"
            className="w-full col-span-4 sm:col-start-2 sm:col-end-8 lg:col-start-3 lg:col-end-11 xl:col-start-4 xl:col-end-10 px-10"
          />
        </Grid>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1440 240"
          fill="none"
          height="120"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full relative"
        >
          <path
            d="M0.5 239.5V0.614618C541.242 112.991 855.796 117.012 1439.5 0.60957V239.5H0.5Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </div>
      <Section className="pb-12 pt-5" innerClassName="pt-0">
        <RichText
          document={content.hero[0].document}
          className="text-center col-span-6 xl:col-start-5 xl:col-end-10 mb-16.25 xl:-ml-16 max-w-none"
        />
        <OfferCard
          price={cost}
          paymentUrl={paymentUrl}
          duration={durationOfTenancy}
          className="row-start-2 col-span-6 md:col-start-2 md:col-end-6 xl:-ml-16 xl:col-start-5 xl:col-end-10"
        />
      </Section>
      <Section className="pb-100-20vw sm:pb-4 lg:pb-20 relative overflow-visible bg-grey-1">
        <RichText
          document={content.sections[0].document}
          fullPageWidth
          disableProse
          className="text-center text-4xl font-serif mb-6"
        />
        <ol className="col-span-6 xl:col-span-12">
          <Grid
            HTMLelement="li"
            className="max-w-7xl mx-auto py-16 items-center relative"
          >
            <div className="sm:row-start-1 col-span-4 sm:col-start-1 sm:col-end-5 ml-10 lg:ml-0 mb-7 lg:mb-0 lg:col-start-2 lg:col-end-6 relative">
              <TextStroke className="text-160px font-bold absolute -ml-12 -top-24">
                1
              </TextStroke>
              <h3 className="text-28px mb-8 max-w-300px relative z-10">
                {content.sections[1].header}
              </h3>
              <RichText
                document={content.sections[1].text}
                className="text-sm"
              />
            </div>
            <VectorGraphic
              name="laptopDesk"
              className="w-full col-span-4 sm:col-start-6 sm:col-end-9 lg:col-start-8 lg:col-end-13"
            />
          </Grid>
          <Grid HTMLelement="li" className="py-16 items-center relative">
            <VectorGraphic
              name="laptopStanding"
              className="w-full col-span-4 sm:col-start-1 sm:col-end-4 lg:col-start-1 lg:col-end-5"
            />
            <div className="row-start-1 col-span-4 sm:col-start-5 sm:col-end-9 ml-10 lg:ml-0 mb-7 lg:mb-0 lg:col-start-8 lg:col-end-12 relative">
              <TextStroke className="text-160px font-bold absolute -ml-12 -top-24">
                2
              </TextStroke>
              <h3 className="text-28px mb-8 max-w-300px relative z-10">
                {content.sections[2].header}
              </h3>
              <RichText
                document={content.sections[2].text}
                className="text-sm"
              />
            </div>
          </Grid>
          <Grid HTMLelement="li" className="py-16 items-center relative">
            <div className="col-span-6 sm:col-start-1 sm:col-end-5 ml-10 xl:ml-0 mb-7 xl:mb-0 xl:col-start-2 xl:col-end-6 relative">
              <TextStroke className="text-160px font-bold absolute -ml-12 -top-24">
                3
              </TextStroke>
              <h3 className="text-28px mb-8 max-w-300px relative z-10">
                {content.sections[3].header}
              </h3>
              <RichText
                document={content.sections[3].text}
                className="text-sm"
              />
            </div>
          </Grid>
        </ol>
        <VectorGraphic
          name="road"
          className="hidden xl:block absolute bottom-9 left-53vw-280 z-10"
        />
      </Section>
      <Section
        className="text-white bg-gray-light"
        innerClassName="relative pb-40rem sm:pb-52 lg:pb-80"
      >
        <Grid className="bg-pink-dark col-span-6 xl:col-span-12 absolute w-full max-w-screen-xl h-full -top-20">
          <div className="row-start-2 lg:row-start-1 col-span-4 sm:col-start-1 sm:col-end-8 lg:col-start-2 lg:col-end-6 lg:mt-3.75rem">
            <h3 className="font-serif mb-5 text-28px">
              {content.sections[4].header}
            </h3>
            <p className="mb-10 max-w-xs">{content.sections[4].description}</p>

            <ButtonExternalLink
              href={`/.netlify/functions/checkout?priceID=${priceID}&customerID=${customerID}&duration=${durationOfTenancy}`}
              label="Go to payment"
              size="large"
              type="primary"
              theme="pink"
              inverse
            />
          </div>
          <figure className="relative col-start-1 col-end-5 sm:col-start-6 sm:col-end-9 lg:pt-64 lg:col-start-7 lg:col-end-13">
            <VectorGraphic
              name="movingIn"
              className="absolute bottom-6 sm:bottom-12 lg:bottom-0"
            />
          </figure>
        </Grid>
      </Section>
    </Layout>
  )
}

export default Eligible

export const query = graphql`
  query eligible {
    storyblokEntry(full_slug: { eq: "eligible" }) {
      content
      name
    }
  }
`
