import styled from "styled-components"

const TextStroke = styled.span`
  @supports (-webkit-text-stroke: 1px #E8E8E8) {
    -webkit-text-stroke: 1px #E8E8E8;
    -webkit-text-fill-color: transparent;
  }
`

export default TextStroke
