import React from "react"
import { ButtonExternalLink } from "../atoms/Button"
import Icon from "../atoms/icons"

const OfferCard = ({ price, paymentUrl, duration, className }) => {
  return (
    <div className={`${className}`}>
      <h3 className="text-center uppercase font-semibold py-4 bg-blue-lightest">
        one off payment of:
      </h3>
      <div className="bg-blue-lightest text-center p-4 pt-0 w-full xl:flex xl:flex-row">
        <div className="flex items-center justify-center xl:justify-start m-auto xl:m-none mb-4 xl:m-0 xl:w-8/12">
          <p
            className={`transition duration-500 ease-in text-52px font-serif text-right pr-4 w-52 ${
              isNaN(price) ? "opacity-0" : "opacity-100"
            }`}
          >
            {!isNaN(price) ? `£${price}` : ""}
          </p>
          <p className="pl-5 py-2 text-xs max-w-120 text-left border-l lg:w-auto border-gray">
            For {duration} months Guarantid
          </p>
        </div>
        <ButtonExternalLink
          className="w-full block xl:w-5/12"
          label="Go to payment"
          size="large"
          theme="pink"
          href={paymentUrl}
        />
      </div>

      <ul className="p-4 bg-blue-midLight">
        <li className="flex items-center pb-4">
          <Icon name="lockShield" className="mr-2" />
          <p>
            <strong>100% refundable</strong> if anything changes
          </p>
        </li>
        <li className="flex items-center pb-4">
          <Icon name="piggyBank" className="mr-2" />
          <p>
            On Average we are <strong> 45% cheaper</strong> than our competition
          </p>
        </li>
        <li className="flex items-center pb-4">
          <Icon name="houseFlat" className="mr-2" />
          <p>
            <strong>91% of agencies </strong> accept our services
          </p>
        </li>
      </ul>
    </div>
  )
}

export default OfferCard
